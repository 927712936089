<template>
  <div class="risk-add-wrapper">
    <van-nav-bar
      fixed
      :title="
        !pageId
          ? `新增${TEXT_CONTROL_RISK_TITLE}`
          : !editable
          ? `修改${TEXT_CONTROL_RISK_TITLE}`
          : TEXT_CONTROL_RISK_TITLE
      "
      left-arrow
      :border="false"
      @click-left="onClickLeft"
    >
      <template v-if="btnAuth.update" #right>
        <span v-if="editable" class="right-btn" @click="editable = false"
          >编辑</span
        >
        <template v-else>
          <span class="right-btn" @click="save">保存</span>
        </template>
      </template>
    </van-nav-bar>
    <div class="form-list">
      <van-form ref="vanForm" :show-error-message="false" @submit="onSubmit">
        <div v-for="item in formDataCom" :key="item.groupType">
          <div v-if="item.groupName" class="group-title">
            {{ item.groupName }}
            <span
              v-if="item.groupType === 1 && item.method === 'LS'"
              :class="'levelspan level' + levelNameCom.level"
              >{{ levelNameCom.text }}</span
            >
            <span
              v-if="item.groupType === 1 && item.method === 'LEC'"
              :class="'levelspan level' + levelNameLecCom.level"
              >{{ levelNameLecCom.text }}</span
            >
            <span
              v-if="item.groupType === 1 && editable"
              class="status"
              :class="'_' + form.status"
              >{{ form.statusName }}</span
            >
          </div>
          <van-field
            v-for="(c, i) in item.list.filter(
              a => !a.method || a.method === item.method
            )"
            v-show="!c.isHide"
            :key="item.group + '-' + i"
            v-model="c.value"
            v-bind="c.fieldProp"
            :label-width="140"
            :is-link="
              ['select', 'select-person', 'dept'].includes(c.type) &&
                !editable &&
                (['area', 'pointId'].includes(c.prop) ? !pageId : true)
            "
            :readonly="
              ['select', 'select-person', 'dept'].includes(c.type) || editable
            "
            :value="c.placeholder"
            :rules="[
              {
                required:
                  c.required !== 'depend'
                    ? c.required
                    : !!getPropValue(form, c.dependRequiredProp),
                message: `${c.label}必填`
              }
            ]"
            :required="
              c.required !== 'depend'
                ? c.required && !editable
                : !!getPropValue(form, c.dependRequiredProp)
            "
            :placeholder="!editable ? c.placeholder : '--'"
            input-align="right"
            @input="v => fieldInput(v, c)"
            @click="select(c, item)"
          >
            <template #label>
              <span class="form-label">{{
                (c.hierarchyMapIndex
                  ? hierarchyMap[c.hierarchyMapIndex] || ""
                  : "") + c.label
              }}</span>
            </template>
            <template v-if="item.method === 'LS'" #input>
              <span v-if="c.value">{{ computedScore(c) }}</span>
              <span v-else style="color: #c8c9cc">请选择</span>
            </template>
          </van-field>
        </div>
        <div>
          <div class="group-title">管控措施</div>
          <template v-if="editable && form.measureList.length === 0">
            <van-cell class="measure-cell-add mb8">
              <template #title>
                <span>暂无管控措施</span>
              </template>
            </van-cell>
          </template>
          <template v-else-if="editable && form.measureList.length">
            <div class="mb8">
              <van-field
                v-for="(item, index) in form.measureList"
                :key="index"
                v-model="item.content"
                rows="1"
                autosize
                type="textarea"
                readonly
                input-align="right"
                :label="item.measuresName"
                placeholder=""
              />
            </div>
          </template>
          <template v-else-if="!editable">
            <div
              v-for="(item, index) in form.measureList"
              :key="index"
              class="measure-box"
            >
              <i
                class="iconfont iconfont-shanchu"
                @click="removeMeasure(item, index)"
              ></i>
              <div class="select-measurelist">
                <input
                  v-model="item.measuresName"
                  type="text"
                  readonly
                  placeholder="请选择类别"
                  @click="item.visiable = true"
                />
              </div>
              <div class="selec-measure-text">
                <input
                  v-model="item.content"
                  type="text"
                  placeholder="请输入内容"
                />
              </div>
              <template v-if="isYn">
                <van-popup v-model="item.visiable" round position="bottom">
                  <van-cascader
                    v-model="item.measureCode"
                    title="管控措施类别"
                    :options="item.list"
                    :field-names="{
                      text: 'label',
                      value: 'value',
                      children: 'children'
                    }"
                    @close="item.visiable = false"
                    @finish="obj => cascaderFinish(obj, item)"
                  />
                </van-popup>
              </template>
              <template v-else>
                <SelectPopupGrade
                  :ref="'selectPopupGradeMeasureList' + index"
                  v-model="item.measureCode"
                  :filterable="false"
                  title="管控措施类别"
                  list-key="value"
                  :list.sync="item.list"
                  :visible="item.visiable"
                  @change="v => popupGradeChangeMeasure(v, item)"
                  @close="item.visiable = false"
                />
              </template>
            </div>
            <van-cell class="measure-cell-add mb8">
              <template #title>
                <span class="add-measure" @click="addMeasure"
                  ><i class="iconfont iconfont-xinzeng"></i>新增管控措施</span
                >
              </template>
            </van-cell>
          </template>
          <van-field
            v-model="form.remark"
            class="mb8"
            :label-width="60"
            :placeholder="editable ? '--' : '请输入'"
            input-align="right"
            rows="1"
            autosize
            type="textarea"
            label="备注"
            maxlength="800"
            :readonly="editable"
          >
          </van-field>
          <template v-if="editable">
            <van-field
              v-model="form.uuserName"
              :label-width="100"
              placeholder=""
              input-align="right"
              autosize
              label="更新人"
            >
            </van-field>
            <van-field
              v-model="form.utime"
              :label-width="100"
              placeholder=""
              input-align="right"
              autosize
              label="更新时间"
            >
            </van-field>
          </template>
        </div>
      </van-form>
    </div>
    <template v-for="item in formDataCom">
      <div :key="'group-' + item.groupType">
        <template v-for="(c, i) in item.list">
          <SelectPopupGrade
            v-if="c.type === 'select' && popupVisibleObj[c.prop]"
            :key="'group-' + item.groupType + i"
            :ref="'selectPopupGrade' + c.prop"
            v-model="form[c.prop]"
            :list-key="c.props ? c.props.value : 'value'"
            :multi="c.isMulti"
            :filterable="c.filterable"
            :title="popupVisibleObj[c.prop].title"
            :list.sync="popupVisibleObj[c.prop].list"
            :visible="popupVisibleObj[c.prop].visiable"
            :props="c.props || { label: 'label', value: 'value' }"
            @change="v => popupGradeChange(v, c, c.isMulti)"
            @close="popupVisibleObj[c.prop].visiable = false"
          >
            <div
              v-if="customSlotProps.includes(c.prop)"
              slot="content"
              slot-scope="scope"
              class="select-slot-content"
              :class="{ active: scope.row.isActive }"
            >
              <div class="levelc-index">{{ scope.row[c.props.showValue] }}</div>
              <div
                class="levelc-content"
                v-html="scope.row[c.props.label]"
              ></div>
            </div>
          </SelectPopupGrade>
          <cascade-department
            v-else-if="c.type === 'dept' && popupVisibleObj[c.prop]"
            :key="'group-' + item.groupType + i"
            v-model="form.hierarchyList[c.prop2].relationId"
            :visible="popupVisibleObj[c.prop][c.prop2].visiable"
            @confirm="v => confirmDepartment(v, c.prop2)"
            @close="popupVisibleObj[c.prop][c.prop2].visiable = false"
          ></cascade-department>
          <select-person
            v-if="c.type === 'select-person' && popupVisibleObj[c.prop]"
            :key="'group-' + item.groupType + i"
            title="选择人员(多选)"
            :visible="popupVisibleObj[c.prop].visiable"
            :show-department-name="true"
            :show-phone-number="false"
            position="bottom"
            :multiple="true"
            :choose-value="form[c.prop]"
            @close="popupVisibleObj[c.prop].visiable = false"
            @confirm="
              (ids, list) => onPersonConfirm(ids, list, c.hierarchyMapIndex)
            "
          />
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { Cascader } from "vant";
import {
  getRiskAccidentSelect,
  getRiskFactorType,
  getAreaSelect,
  getPointByAreaSelect,
  getRiskPointDetail,
  getConsequenceSelect,
  getRiskL,
  getRiskS,
  getMeasuresSelect,
  saveRisk,
  getHierarchySelect as getHierarchy,
  getRiskLecL,
  getRiskLecE,
  getRiskLecC,
  getRiskDetail,
  updateRisk,
  getPositionSelect,
  getControlMeasuresCascadeDic,
  queryRiskAreaLSInfo,
  getLevelSelect
} from "@/api/psm/risk";
import SelectPopupGrade from "@/components/SelectPopupGrade";
import auth from "@/api/authorization";
import { mapMutations, mapState } from "vuex";
import cloneDeep from "lodash/cloneDeep";
import addMixin from "./addMixin";
import SelectPerson from "@/components/SelectPerson.vue";
import projectTextConfig from "@/utils/projectTextConfig";
const isShowRiskFile = window.globalConfig.VUE_APP_ADMIN_RISK_FILE === "true";
import { ratingMethodTypes } from "@/views/risk/utils/constant";

export default {
  components: {
    SelectPopupGrade,
    SelectPerson,
    [Cascader.name]: Cascader
  },
  mixins: [addMixin],
  data() {
    return {
      hierarchyMap: {},
      pageId: this.$route.query.id,
      editable: !!this.$route.query.id,
      curSelect: {
        parent: {},
        cur: {}
      },
      measureListSelect: [],
      popupVisibleObj: {
        dangerFactorCode: {
          title: "风险有害因素类型",
          list: [],
          visiable: false
        },
        eventTypeCode: {
          title: "可能导致的事故",
          list: [],
          visiable: false
        },
        area: {
          title: projectTextConfig.TEXT_CONTROL_RISK_AREA_TITLE,
          list: [],
          visiable: false
        },
        pointId: {
          title: projectTextConfig.TEXT_RISK_POINT_TITLE,
          list: [],
          visiable: false
        },
        accidentConsequence: {
          title: "事故后果",
          list: [],
          visiable: false
        },
        probabilityLevel: {
          title: "事故发生的可能性(L)",
          list: [],
          visiable: false
        },
        gravityLevel: {
          title: "事件后果严重性(S)",
          list: [],
          visiable: false
        },
        levelL: {
          title: "事故发生的可能性(L)",
          list: [],
          visiable: false
        },
        levelE: {
          title: "暴露频繁程度(E)",
          list: [],
          visiable: false
        },
        levelC: {
          title: "后果严重性(C)",
          list: [],
          visiable: false
        },
        riskHierarchyCode: {
          title: "管控层级",
          list: [],
          visiable: false
        },
        hierarchyList: {
          0: {
            title: "公司级",
            list: [],
            visiable: false
          },
          1: {
            title: "车间级",
            list: [],
            visiable: false
          },
          2: {
            title: "岗位级",
            list: [],
            visiable: false
          },
          4: {
            title: "部门级",
            list: [],
            visiable: false
          }
        },
        hierarchyList3: {
          title: "岗位级",
          list: [],
          visiable: false
        },
        hierarchyListUser1: {
          title: "公司负责人",
          list: [],
          visiable: false
        },
        hierarchyListUser2: {
          title: "车间负责人",
          list: [],
          visiable: false
        },
        hierarchyListUser3: {
          title: "工段负责人",
          list: [],
          visiable: false
        },
        hierarchyListUser4: {
          title: "岗位负责人",
          list: [],
          visiable: false
        },
        hierarchyListUser5: {
          title: "部门负责人",
          list: [],
          visiable: false
        }
      },
      formData: [
        {
          groupName: "",
          groupType: 0,
          list: [
            {
              label: projectTextConfig.TEXT_CONTROL_RISK_HAZARDOUS_FACTORS,
              required: true,
              type: "text",
              placeholder: "请输入",
              detailProp: "hazardousFactors",
              prop: "hazardousFactors",
              value: "",
              fieldProp: {
                maxlength: 200
              }
            },
            {
              label: projectTextConfig.TEXT_CONTROL_RISK_AREA_TITLE,
              required: true,
              placeholder: "请选择",
              type: "select",
              value: "",
              detailProp: "areaName",
              prop: "area"
            },
            {
              label: projectTextConfig.TEXT_RISK_POINT_TITLE,
              required: true,
              placeholder: "请选择",
              type: "select",
              value: "",
              detailProp: "pointName",
              prop: "pointId"
            },
            {
              label: "风险有害因素类型",
              required: false,
              type: "select",
              placeholder: "请选择",
              value: "",
              detailProp: "dangerFactorName",
              prop: "dangerFactorCode",
              filterable: false
            },
            {
              label: "可能导致的事故",
              required: true,
              type: "select",
              isMulti: true,
              placeholder: "请选择",
              value: "",
              detailProp: "eventTypeName",
              prop: "eventTypeCode",
              paramsType: "Array",
              key: 1
            },
            {
              label: "事故后果",
              required: false,
              type: "select",
              isMulti: true,
              placeholder: "请选择",
              detailProp: "accidentConsequenceName",
              prop: "accidentConsequence",
              value: "",
              filterable: false
            }
          ]
        },
        {
          groupName: "安全风险等级",
          groupType: 1,
          method: "no",
          list: [
            {
              label: "事故发生的可能性(L)",
              required: true,
              type: "select",
              placeholder: "请选择",
              value: "",
              detailProp: "probabilityLevel",
              prop: "probabilityLevel",
              filterable: false,
              props: { label: "label", value: "value", showValue: "score" },
              showLabelProp: "value",
              method: "LS"
            },
            {
              label: "事件后果严重性(S)",
              detailProp: "gravityLevel",
              prop: "gravityLevel",
              required: true,
              filterable: false,
              type: "select",
              placeholder: "请选择",
              props: { label: "label", value: "value", showValue: "score" },
              value: "",
              showLabelProp: "value",
              method: "LS"
            },
            {
              label: "事故发生的可能性(L)",
              required: true,
              filterable: false,
              detailProp: "levelL",
              prop: "levelL",
              type: "select",
              placeholder: "请选择",
              props: { label: "label", value: "value", showValue: "score" },
              value: "",
              showLabelProp: "score",
              method: "LEC"
            },
            {
              label: "暴露频繁程度(E)",
              detailProp: "levelE",
              prop: "levelE",
              required: true,
              filterable: false,
              type: "select",
              placeholder: "请选择",
              props: { label: "label", value: "value", showValue: "score" },
              value: "",
              showLabelProp: "score",
              method: "LEC"
            },
            {
              label: "后果严重性(C)",
              detailProp: "levelC",
              prop: "levelC",
              required: true,
              filterable: false,
              type: "select",
              placeholder: "请选择",
              props: { label: "label", value: "value", showValue: "score" },
              value: "",
              showLabelProp: "score",
              method: "LEC"
            }
          ]
        },
        {
          groupName: "管控级别",
          groupType: 2,
          list: [
            {
              label: "管控层级",
              required: true,
              type: "select",
              detailProp: "riskHierarchyName",
              prop: "riskHierarchyCode",
              placeholder: "请选择",
              value: "",
              filterable: false
            },
            {
              label: "公司级",
              required: false,
              type: "dept",
              detailProp: "hierarchyList0",
              prop: "hierarchyList",
              prop2: 0,
              placeholder: "请选择",
              value: "",
              isHide: true
            },
            {
              label: projectTextConfig.TEXT_RISK_POINT_ADD_GK_PL,
              hierarchyMapIndex: "1",
              required: false, // "depend",
              dependRequiredProp: ["hierarchyList", "0", "relationId"],
              detailProp: "hierarchyListUser1",
              prop: "hierarchyListUser1",
              formprops: ["hierarchyList", "0", "userId"],
              propsLength: 3,
              paramsType: "multi",
              type: "select-person",
              placeholder: "请选择",
              value: ""
            },
            {
              label: "部门级",
              required: false,
              type: "dept",
              detailProp: "hierarchyList4",
              prop: "hierarchyList",
              prop2: 4,
              placeholder: "请选择",
              value: "",
              isHide: true
            },
            {
              label: projectTextConfig.TEXT_RISK_POINT_ADD_GK_PL,
              hierarchyMapIndex: "5",
              required: false, // "depend",
              dependRequiredProp: ["hierarchyList", "4", "relationId"],
              detailProp: "hierarchyListUser5",
              prop: "hierarchyListUser5",
              formprops: ["hierarchyList", "4", "userId"],
              propsLength: 3,
              paramsType: "multi",
              type: "select-person",
              placeholder: "请选择",
              value: ""
            },
            {
              label: "车间级",
              required: false,
              type: "dept",
              detailProp: "hierarchyList2",
              prop: "hierarchyList",
              prop2: 1,
              placeholder: "请选择",
              value: "",
              isHide: true
            },
            {
              label: projectTextConfig.TEXT_RISK_POINT_ADD_GK_PL,
              hierarchyMapIndex: "2",
              required: false, // "depend",
              dependRequiredProp: ["hierarchyList", "1", "relationId"],
              type: "select-person",
              detailProp: "hierarchyListUser2",
              prop: "hierarchyListUser2",
              formprops: ["hierarchyList", "1", "userId"],
              propsLength: 3,
              paramsType: "multi",
              placeholder: "请选择",
              value: ""
            },
            {
              label: "工段级",
              required: false,
              type: "dept",
              detailProp: "hierarchyList3",
              prop: "hierarchyList",
              prop2: 2,
              placeholder: "请选择",
              value: "",
              isHide: true
            },
            {
              label: projectTextConfig.TEXT_RISK_POINT_ADD_GK_PL,
              hierarchyMapIndex: "3",
              required: false, // "depend",
              dependRequiredProp: ["hierarchyList", "2", "relationId"],
              type: "select-person",
              detailProp: "hierarchyListUser3",
              prop: "hierarchyListUser3",
              formprops: ["hierarchyList", "2", "userId"],
              propsLength: 3,
              paramsType: "multi",
              placeholder: "请选择",
              value: ""
            },
            {
              label: "岗位级",
              required: false,
              type: "select",
              detailProp: "hierarchyList3",
              prop: "hierarchyList3",
              visiableProp: "hierarchyList3",
              prop2: 3,
              placeholder: "请选择",
              value: "",
              isHide: true
            },
            {
              label: projectTextConfig.TEXT_RISK_POINT_ADD_GK_PL,
              hierarchyMapIndex: "4",
              required: false, // "depend",
              dependRequiredProp: ["hierarchyList", "3", "relationId"],
              type: "select-person",
              isMulti: true,
              formprops: ["hierarchyList", "3", "userId"],
              propsLength: 3,
              paramsType: "multi",
              detailProp: "hierarchyListUser4",
              prop: "hierarchyListUser4",
              placeholder: "请选择",
              value: ""
            }
          ]
        }
      ],
      form: {
        gravityLevel: "", // S
        probabilityLevel: "", // L
        remark: "", // 备注
        levelL: "",
        levelE: "",
        levelC: "",
        pointId: "", // 风险点id
        dangerFactorCode: "", // 危险有害因素类型
        ratingMethod: "", // 评分方法
        hazardousFactors: "", // 危险有害因素
        riskHierarchyCode: "", // 管控层级
        eventTypeCode: [], // 可能导致的事故, 多选
        accidentConsequence: "", // 事故后果, 多选
        hierarchyList: [
          {
            hierarchyCode: "1",
            relationId: "", // 级别id
            userId: "" // 负责人id
          },
          {
            hierarchyCode: "2",
            relationId: "",
            userId: ""
          },
          {
            hierarchyCode: "3",
            relationId: "",
            userId: ""
          },
          {
            hierarchyCode: "4",
            relationId: "",
            userId: ""
          },
          {
            hierarchyCode: "5",
            relationId: "",
            userId: ""
          }
        ], // 管控层级集合
        measureList: [], // 管控措施集合
        uuserName: "",
        utime: "",
        status: "",
        statusName: "",
        controlTypeOptions: []
      },
      schema: null,
      riskOptions: null
    };
  },
  created() {
    this.customSlotProps = [
      "probabilityLevel",
      "gravityLevel",
      "levelL",
      "levelE",
      "levelC"
    ];
    this.getSelect();
    // 去查询详情
    this.getRiskDetail();
  },
  computed: {
    formDataCom() {
      return this.formData.filter(a => a.method !== "no");
    },
    levelNameCom() {
      const { probabilityLevel, gravityLevel } = this.form;
      if (
        !this.schema ||
        !this.riskOptions ||
        !probabilityLevel ||
        !gravityLevel
      ) {
        return {};
      }
      const possible = this.schema.L?.data.find(
        item => item.groupId === probabilityLevel
      );
      const grave = this.schema.S?.data.find(
        item => item.groupId === gravityLevel
      );
      const possibleLevel = possible["等级"];
      const graveLevel = grave["等级"];
      const curLine = this.schema.matrix.data.find(
        v => v["等级"] === possibleLevel
      );
      const level = curLine[graveLevel];
      const levelNameC = this.riskOptions.find(v => +v.value === level);
      return {
        level,
        text: levelNameC.label
      };
    },
    levelNameLecCom() {
      if (!this.form.levelL || !this.form.levelE || !this.form.levelC) {
        return { level: 0, text: "" };
      }
      const r = this.form.levelL * this.form.levelE * this.form.levelC;
      if (r >= 320) {
        return { level: 4, text: "极其危险" };
      } else if (r > 160 && r <= 319) {
        return { level: 3, text: "高度危险" };
      } else if (r >= 70 && r <= 159) {
        return { level: 2, text: "显著危险" };
      } else if (r < 70) {
        return { level: 1, text: "轻度危险" };
      } else {
        return { level: 0, text: "" };
      }
    },
    ...mapState({
      userInfo: state => state.login.userInfo,
      btnAuth: state => state.menu.btnAuth
    }),
    computedScore() {
      return c => {
        const list = this.popupVisibleObj[c.detailProp].list;
        return list?.find(v => v.value === c.value)?.score;
      };
    }
  },
  methods: {
    ...mapMutations({
      setNeedRefreshList: "setNeedRefreshList"
    }),
    cascaderFinish({ selectedOptions }, item) {
      item.measuresName = selectedOptions.map(option => option.label).join("/");
      item.visiable = false;
    },
    onPersonConfirm(ids, list, hierarchyCode) {
      this.form[this.curSelect.cur.prop] = list;
      this.curSelect.cur.value = list.map(a => a.label).join(" ");
      const hierarchyList = [];
      list.forEach(item => {
        hierarchyList.push({
          hierarchyCode,
          relationId: "",
          userId: item.value,
          userName: item.label
        });
      });
      const index = this.form.hierarchyList.findIndex(
        a => a.hierarchyCode == hierarchyCode
      );
      if (index !== -1) {
        const l = this.form.hierarchyList.filter(
          a => a.hierarchyCode != hierarchyCode
        );
        this.form.hierarchyList = [...l, ...hierarchyList];
      } else {
        this.form.hierarchyList = [
          ...this.form.hierarchyList,
          ...hierarchyList
        ];
      }
    },
    cancel() {
      this.editable = true;
      this.resetDetail(this.bakDetail);
    },
    resetDetail(res) {
      const h1 = res.hierarchyList.filter(a => a.hierarchyCode == "1");
      res["hierarchyList1"] = h1.map(a => a.relationName).join(" "); // item.relationName;
      res["hierarchyListUser1"] = h1.map(a => a.userName).join(" "); // item.userName.toString();
      const h2 = res.hierarchyList.filter(a => a.hierarchyCode == "2");
      res["hierarchyList2"] = h2.map(a => a.relationName).join(" "); // item.relationName;
      res["hierarchyListUser2"] = h2.map(a => a.userName).join(" "); // item.userName.toString();
      const h3 = res.hierarchyList.filter(a => a.hierarchyCode == "3");
      res["hierarchyList3"] = h3.map(a => a.relationName).join(" "); // item.relationName;
      res["hierarchyListUser3"] = h3.map(a => a.userName).join(" "); // item.userName.toString();
      const h4 = res.hierarchyList.filter(a => a.hierarchyCode == "4");
      res["hierarchyList4"] = h4.map(a => a.relationName).join(" "); // item.relationName;
      res["hierarchyListUser4"] = h4.map(a => a.userName).join(" "); // item.userName.toString();
      const h5 = res.hierarchyList.filter(a => a.hierarchyCode == "5");
      res["hierarchyList5"] = h5.map(a => a.relationName).join(" ");
      res["hierarchyListUser5"] = h5.map(a => a.userName).join(" ");

      // res.hierarchyList.forEach((item, index) => {
      //   res["hierarchyList" + index] = item.relationName;
      //   res["hierarchyListUser" + index] = item.userName.toString();
      //   // 如果级别有值, 则需要去请求责任人列表
      //   if (item.hierarchyCode === "4") {
      //     // 获取岗位负责人
      //     this.getUsersByPos(item.relationId);
      //   } else if (item.relationId) {
      //     this.confirmDepartment({ id: item.relationId }, index);
      //   }
      // });
      if (!res.measureList) {
        res.measureList = [];
      } else {
        res.measureList.map(item => {
          item.visiable = false;
          item.list = cloneDeep(this.measureListSelect);
          return item;
        });
      }
      // 根据详情内容回显数据
      this.resetShowFormData(res);
      // 根据详情内容回显设置表单数据
      this.resetShowForm(res);
    },
    getRiskDetail() {
      const { id } = this.$route.query;
      if (!id) {
        return;
      }
      getRiskDetail(id).then(res => {
        if (!res.hierarchyList) {
          res.hierarchyList = [
            {
              hierarchyCode: "1",
              hierarchyName: "公司级",
              relationId: "",
              relationName: null,
              userId: "",
              userName: []
            },
            {
              hierarchyCode: "5",
              hierarchyName: "部门级",
              relationId: "",
              relationName: null,
              userId: "",
              userName: []
            },
            {
              hierarchyCode: "2",
              hierarchyName: "车间级",
              relationId: "",
              relationName: null,
              userId: "",
              userName: []
            },
            {
              hierarchyCode: "3",
              hierarchyName: "工段级",
              relationId: "",
              relationName: null,
              userId: "",
              userName: []
            },
            {
              hierarchyCode: "4",
              hierarchyName: "岗位级",
              relationId: "",
              relationName: null,
              userId: "",
              userName: []
            }
          ];
        }
        const index1 = res.hierarchyList.findIndex(a => a.hierarchyCode == "1");
        if (index1 === -1) {
          res.hierarchyList.push({
            hierarchyCode: "1",
            hierarchyName: "公司级",
            relationId: "",
            relationName: null,
            userId: "",
            userName: []
          });
        }
        const index5 = res.hierarchyList.findIndex(a => a.hierarchyCode == "5");
        if (index5 === -1) {
          res.hierarchyList.push({
            hierarchyCode: "5",
            hierarchyName: "部门级",
            relationId: "",
            relationName: null,
            userId: "",
            userName: []
          });
        }
        const index2 = res.hierarchyList.findIndex(a => a.hierarchyCode == "2");
        if (index2 === -1) {
          res.hierarchyList.push({
            hierarchyCode: "2",
            hierarchyName: "车间级",
            relationId: "",
            relationName: null,
            userId: "",
            userName: []
          });
        }
        const index3 = res.hierarchyList.findIndex(a => a.hierarchyCode == "3");
        if (index3 === -1) {
          res.hierarchyList.push({
            hierarchyCode: "3",
            hierarchyName: "工段级",
            relationId: "",
            relationName: null,
            userId: "",
            userName: []
          });
        }
        const index4 = res.hierarchyList.findIndex(a => a.hierarchyCode == "4");
        if (index4 === -1) {
          res.hierarchyList.push({
            hierarchyCode: "4",
            hierarchyName: "岗位级",
            relationId: "",
            relationName: null,
            userId: "",
            userName: []
          });
        }
        this.bakDetail = cloneDeep(res);
        this.resetDetail(res);
      });
    },
    resetShowFormData(res) {
      for (let i = 0, len = this.formData.length; i < len; i++) {
        const p = this.formData[i];
        if (p.method || p.method === "") {
          const method = ratingMethodTypes.find(
            v => v.value === res.ratingMethod
          )?.label;
          p.method = method;
        }
        for (let j = 0, l = p.list.length; j < l; j++) {
          const c = p.list[j];
          this.formData[i].list[j].value = res[c.detailProp];
        }
      }
    },
    resetShowForm(res) {
      for (let key in this.form) {
        this.form[key] = res[key];
        if (key === "hierarchyList") {
          const h1 = res.hierarchyList.filter(a => a.hierarchyCode == "1");
          this.form["hierarchyList1"] = h1.map(a => a.relationId).join(" "); // item.relationName;
          this.form["hierarchyListUser1"] = h1.map(a => ({
            label: a.userName,
            value: a.userId
          }));
          const h2 = res.hierarchyList.filter(a => a.hierarchyCode == "2");
          this.form["hierarchyList2"] = h2.map(a => a.relationId).join(" "); // item.relationName;
          this.form["hierarchyListUser2"] = h2.map(a => ({
            label: a.userName,
            value: a.userId
          }));
          const h3 = res.hierarchyList.filter(a => a.hierarchyCode == "3");
          this.form["hierarchyList3"] = h3.map(a => a.relationId).join(" "); // item.relationName;
          this.form["hierarchyListUser3"] = h3.map(a => ({
            label: a.userName,
            value: a.userId
          }));
          const h4 = res.hierarchyList.filter(a => a.hierarchyCode == "4");
          this.form["hierarchyList4"] = h4.map(a => a.relationId).join(" "); // item.relationName;
          this.form["hierarchyListUser4"] = h4.map(a => ({
            label: a.userName,
            value: a.userId
          }));
          const h5 = res.hierarchyList.filter(a => a.hierarchyCode == "5");
          this.form["hierarchyList5"] = h5.map(a => a.relationId).join(" "); // item.relationName;
          this.form["hierarchyListUser5"] = h5.map(a => ({
            label: a.userName,
            value: a.userId
          }));
        }
      }
    },
    removeMeasure(item, index) {
      this.form.measureList.splice(index, 1);
    },
    addMeasure() {
      this.form.measureList.push({
        list: cloneDeep(this.measureListSelect),
        visiable: false,
        measuresName: "",
        measureCode: "",
        content: ""
      });
    },
    onConfirmDate(v) {},
    getPropValue(obj, prop) {
      if (prop.length > 1) {
        for (let i = 0, len = prop.length; i < len; i++) {
          const arr = prop.slice(1);
          return this.getPropValue(obj[prop[i]], arr);
        }
      }
      return obj[prop[0]];
    },
    // 选择管控层级级别
    confirmDepartment(department, type) {
      this.curSelect.cur.value = department.label;
      if (type === 0) {
        // 如果是公司级/车间级/工段级
        this.form.hierarchyList[1].userId = "";
        this.clearFormDataProp("hierarchyListUser1", "detailProp");
      } else if (type === 1) {
        // 如果是公司级/车间级/工段级
        this.form.hierarchyList[2].userId = "";
        this.clearFormDataProp("hierarchyListUser2", "detailProp");
      } else if (type === 2) {
        // 如果是公司级/车间级/工段级
        this.form.hierarchyList[3].userId = "";
        this.clearFormDataProp("hierarchyListUser3", "detailProp");
      } else if (type === 4) {
        this.form.hierarchyList[4].userId = "";
        this.clearFormDataProp("hierarchyListUser4", "detailProp");
      }
      // 去查询负责人 type: 0/1/2/3
      this.getSelectUsersTypes(department.id).then(res => {
        this.popupVisibleObj[`hierarchyListUser${type}`].list = res;
      });
    },
    fieldInput(v, obj) {
      if (obj.type !== "text") {
        return;
      }
      this.form[obj.prop] = v;
    },
    select(c, parent) {
      if (c.type === "text" || this.editable) {
        return;
      }
      // 如果是编辑, 并且是区域和风险点则不可修改
      if (this.pageId && ["area", "pointId"].includes(c.prop)) {
        return;
      }
      this.curSelect.parent = parent;
      this.curSelect.cur = c;
      if (c.type === "dept") {
        this.popupVisibleObj[c.prop] &&
          this.popupVisibleObj[c.prop][c.prop2] &&
          (this.popupVisibleObj[c.prop][c.prop2].visiable = true);
      } else {
        if (c.visiableProp) {
          this.popupVisibleObj[c.visiableProp].visiable = true;
          return;
        }
        if (this.popupVisibleObj[c.prop]) {
          if (c.prop2 || c.prop2 === 0) {
            this.popupVisibleObj[c.prop][c.prop2].visiable = true;
          } else {
            this.popupVisibleObj[c.prop].visiable = true;
          }
        }
      }
    },
    getSelect() {
      // 风险下拉
      getLevelSelect().then(res => {
        this.riskOptions = res;
      });
      // 风险有害因素下拉
      getRiskFactorType().then(res => {
        this.popupVisibleObj["dangerFactorCode"].list = res;
      });
      getRiskAccidentSelect().then(res => {
        this.popupVisibleObj["eventTypeCode"].list = res;
      });
      // 获取区域
      getAreaSelect().then(res => {
        this.popupVisibleObj["area"].list = res;
      });
      // 事故后果下拉
      getConsequenceSelect().then(res => {
        this.popupVisibleObj["accidentConsequence"].list = res;
      });
      queryRiskAreaLSInfo().then(res => {
        this.schema = res;
        // L下拉
        const l = res.L.data.map(v => {
          const label = Object.keys(v).reduce((res, cur) => {
            if (cur === "groupId" || cur === "等级") {
              return res;
            }
            return `${res}${v[cur]}<br/>`;
          }, "");
          return {
            label,
            score: v["等级"],
            value: v.groupId
          };
        });
        const s = res.S.data.map(v => {
          const label = Object.keys(v).reduce((res, cur) => {
            if (cur === "groupId" || cur === "等级") {
              return res;
            }
            return `${res}${v[cur]}<br/>`;
          }, "");
          return {
            label,
            score: v["等级"],
            value: v.groupId
          };
        });
        this.popupVisibleObj["probabilityLevel"].list = l;
        this.popupVisibleObj["gravityLevel"].list = s;
      });
      getRiskLecL().then(res => {
        const r = res.map(a => {
          return {
            label: `${a.criterion}`,
            score: a.score,
            value: a.score
          };
        });
        this.popupVisibleObj["levelL"].list = r;
      });
      getRiskLecE().then(res => {
        const r = res.map(a => {
          return {
            label: `${a.criterion}`,
            score: a.score,
            value: a.score
          };
        });
        this.popupVisibleObj["levelE"].list = r;
      });
      getRiskLecC().then(res => {
        const r = res.map(a => {
          return {
            label: `${a.legalRequirements}<br/>${a.person}<br/>${a.economicLosses}<br/>${a.stoppage}<br/>${a.corporateImage}`,
            score: a.score,
            value: a.score
          };
        });
        this.popupVisibleObj["levelC"].list = r;
      });
      // 管控层级
      getHierarchy().then(res => {
        this.popupVisibleObj["riskHierarchyCode"].list = res;
        res.forEach(item => {
          this.$set(this.hierarchyMap, item.value, item.label);
        });
      });
      if (this.isYn) {
        getControlMeasuresCascadeDic().then(res => {
          this.measureListSelect = res;
        });
      } else {
        // 管控措施
        getMeasuresSelect().then(res => {
          this.measureListSelect = res;
        });
      }
      // 获取岗位
      getPositionSelect().then(res => {
        this.popupVisibleObj["hierarchyList3"].list = res;
      });
    },
    // 根据部门和orgCode获取用户下拉
    async getSelectUsersTypes(department) {
      try {
        const res = await auth.getSelectUsersTypes({
          orgCode: this.userInfo.orgCode,
          department
        });
        return res;
      } catch (error) {
        console.log("error", error);
      }
    },
    // 获取风险点
    getPointByAreaSelect(areaId = "") {
      getPointByAreaSelect({ riskAreaId: areaId }).then(res => {
        this.popupVisibleObj["pointId"].list = res;
      });
    },
    isLEC(v) {
      return v === "104013"; // LEC
    },
    isLS(v) {
      return v === "104012"; // LS
    },
    popupGradeChangeMeasure(v, item) {
      const { label, value } = v[0];
      item.measureCode = value;
      item.measuresName = label;
    },
    clearFormDataProp(prop, propName) {
      for (let i = 0, len = this.formData.length; i < len; i++) {
        const item = this.formData[i];
        for (let j = 0, l = item.list.length; j < l; j++) {
          const obj = item.list[j];
          const p = propName || "prop";
          if (obj[p] === prop) {
            this.formData[i].list[j].value = "";
            return;
          }
        }
      }
    },
    popupGradeChange(v, c, isMulti) {
      const type = c.prop;
      let _label = "";
      let val = "";
      if (isMulti) {
        // 如果多选
        _label = v.map(a => a.label).toString();
        val = v.map(a => a.value).toString();
      } else if (v?.length) {
        const { value, label } = v[0];
        if (c.showLabelProp) {
          _label = v[0][c.showLabelProp] || "";
        } else {
          _label = label || "";
        }
        val = value || "";
      }
      this.curSelect.cur.value = _label;
      // 如果有这个属性, 则直接修改
      if (
        Object.prototype.hasOwnProperty.call(
          this.form,
          this.curSelect.cur.prop
        ) ||
        type.indexOf("hierarchyListUser") !== -1
      ) {
        if (c.paramsType === "Array") {
          this.form[this.curSelect.cur.prop] = val.split(",");
        } else if (c.paramsType === "multi") {
          if (c.propsLength === 3) {
            const [key1, key2, key3] = c.formprops;
            this.form[key1][key2][key3] = val;
          }
        } else {
          this.form[this.curSelect.cur.prop] = val;
        }
      }
      if (type === "area") {
        if (this.form.riskAreaId !== val) {
          this.form.pointId = "";
          this.clearFormDataProp("pointId");
          // 隐藏风险等级
          this.formData[1].method = "no";
        }
        if (!val) {
          this.form.pointId = "";
          this.popupVisibleObj["pointId"].list = [];
        } else {
          this.getPointByAreaSelect(val);
        }
      } else if (type === "pointId") {
        if (!val) {
          this.formData[1].method = "no";
          // LS
          this.form.probabilityLevel = "";
          this.form.gravityLevel = "";
          // LEC
          this.form.levelL = "";
          this.form.levelE = "";
          this.form.levelC = "";
          return;
        }
        // 如果选择的是风险点, 则需要去获取风险点详情
        getRiskPointDetail(val).then(res => {
          if (res.ratingMethodName !== this.formData[1].method) {
            this.formData[1].list.map(item => {
              item.value = "";
            });
            // LS
            this.form.probabilityLevel = "";
            this.form.gravityLevel = "";
            // LEC
            this.form.levelL = "";
            this.form.levelE = "";
            this.form.levelC = "";
          }
          this.formData[1].method = res.ratingMethodName;
          this.form.ratingMethod = res.ratingMethod;
        });
      } else if (type === "hierarchyList4") {
        // 如果是岗位
        if (this.form.hierarchyList[3].relationId !== val) {
          // 清空负责人
          this.form.hierarchyList[3].userId = "";
          // 清空负责人展示
          this.clearFormDataProp("hierarchyListUser4");
        }
        this.form.hierarchyList[3].relationId = val;
        // 获取用户
        this.getUsersByPos(val);
      }
    },
    getUsersByPos(pos) {
      auth
        .getUsersByPos({
          orgCode: this.userInfo.orgCode,
          positions: pos
        })
        .then(res => {
          this.popupVisibleObj["hierarchyListUser4"].list = res;
        });
    },
    onClickLeft() {
      if (this.pageId) {
        // 如果是详情
        if (this.editable) {
          // history.go(-1);
          this.$router.push({ name: "ControlRisk" });
        } else {
          this.cancel();
        }
      } else {
        // 新增的话直接返回
        // history.go(-1);
        this.$router.push({ name: "ControlRisk" });
      }
    },
    onSubmit() {
      const params = cloneDeep(this.form);
      params.hierarchyList = params.hierarchyList.filter(a => a.userId);
      params.measureList = params.measureList.map(a => {
        const { content, measureCode, measuresName } = a;
        return {
          measureCode,
          measuresName,
          content
        };
      });
      if (this.pageId) {
        updateRisk(this.pageId, params).then(res => {
          if (!res) {
            this.$toast.fail("修改失败");
            return;
          }
          this.editable = true;
          this.setNeedRefreshList({ value: true });
          this.$toast.success("修改成功");
          this.getRiskDetail();
        });
        return;
      }
      saveRisk(params).then(res => {
        if (!res) {
          this.$toast.fail("保存失败");
          return;
        }
        // 提示
        this.setNeedRefreshList({ value: true });
        this.$toast.success("保存成功");
        this.onClickLeft();
      });
    },
    save() {
      this.$refs.vanForm.submit();
    }
  }
};
</script>

<style lang="scss" scoped>
.risk-add-wrapper {
  padding: 60px 0 20px 0;
  .right-btn {
    font-size: 16px;
    &:first-child {
      margin-right: 8px;
    }
  }
  .form-list {
    font-size: 14px;
    .group-title {
      position: relative;
      height: 30px;
      line-height: 30px;
      background-color: #eceef2;
      padding: 0 16px;
      .levelspan {
        display: inline-block;
        height: 16px;
        line-height: 16px;
        border-radius: 2px;
        font-size: 12px;
        padding: 0 5px;
        margin-left: 8px;
        &.level4 {
          background-color: #dcecff;
          border: 1px solid #1580ff;
          color: #1580ff;
        }
        &.level3 {
          background-color: #fffbe9;
          border: 1px solid #edc400;
          color: #edc400;
        }
        &.level2 {
          background-color: #ffe7db;
          border: 1px solid #ff6d1e;
          color: #ff6d1e;
        }
        &.level1 {
          background-color: #ffdddd;
          border: 1px solid #f13030;
          color: #f13030;
        }
      }
      .status {
        position: absolute;
        right: 16px;
        top: 0;
        font-size: 14px;
        &._0 {
          color: #f13030;
        }
        &._1 {
          color: #8c8f97;
        }
        &._2 {
          color: #1676ff;
        }
        &._4 {
          color: #ff6d1e;
        }
      }
      &::before {
        position: absolute;
        content: "";
        width: 3px;
        height: 14px;
        background-color: #1676ff;
        border-radius: 2px;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
      }
    }
    .measure-box {
      margin-top: 16px;
      padding: 0 16px;
      height: 36px;
      display: flex;
      align-items: center;
      .iconfont {
        color: #f75858;
        margin-right: 7px;
      }
      .select-measurelist,
      .selec-measure-text {
        box-sizing: border-box;
        border: 1px solid #c7c9d0;
        border-radius: 2px;
        height: 100%;
        input {
          width: 100%;
          height: 100%;
          border: none;
          outline: none;
          box-sizing: border-box;
          font-size: 14px;
          color: #2e2e4d;
        }
      }
      .select-measurelist {
        position: relative;
        width: 130px;
        margin-right: 10px;
        &::after {
          position: absolute;
          content: "";
          top: 50%;
          right: 4px;
          margin-top: -2px;
          border: 4px solid transparent;
          border-top-color: #aeb3c0;
        }
        input {
          padding-right: 16px;
          padding-left: 8px;
        }
      }
      .selec-measure-text {
        flex: 1;
        box-sizing: border-box;
        padding: 0 8px;
      }
    }
    .measure-cell-add {
      .add-measure {
        color: #1676ff;
        font-size: 14px;
        font-weight: 400;
        .iconfont {
          margin-right: 6px;
        }
      }
    }
    .mb8 {
      border-bottom: 8px solid #eceef2;
      &::after {
        display: none;
      }
    }
  }
  .select-slot-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    &.active {
      .levelc-index,
      .levelc-content {
        color: $--color-primary;
      }
    }
    .levelc-index {
      width: 25px;
      text-align: center;
      font-size: 18px;
      color: #2e2e4d;
      margin-right: 20px;
    }
    .levelc-content {
      flex: 1;
      font-size: 12px;
      color: #8c8f97;
      line-height: 17px;
    }
  }
}
</style>
