export const ratingMethodTypes = [
  {
    label: "LS",
    value: "104012"
  },
  {
    label: "LEC",
    value: "104013"
  }
];
