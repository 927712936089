export default {
  methods: {
    onClickLeft() {
      if (this.pageId) {
        // 如果是详情
        if (this.editable) {
          history.go(-1);
        } else {
          this.cancel();
        }
      } else {
        // 新增的话直接返回
        history.go(-1);
      }
    }
  }
};
